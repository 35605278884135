@import url('./styles.scss');

.toast-container {
  min-width: 456px;
  max-width: 600px;
  width: fit-content;
  background-color: rgb(246, 239, 239);
  min-height: 67px;
  color: #050505;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  font-size: 17px;
  margin-bottom: 26px;
  position: relative;

  // Responsive adjustments for tablet
  @media (max-width: 768px) {
    min-width: 350px; // Reduce min-width for smaller screens
    font-size: 15px; // Reduce font size slightly
  }

  // Responsive adjustments for mobile
  @media (max-width: 480px) {
    max-width: 400px; // Further reduce for mobile
    font-size: 12px; // Adjust font size for mobile
    left: 15px;
    bottom: 30px;
  }

  // Responsive adjustments for mobile
  @media (max-width: 390px) {
    max-width: 320px; // Further reduce for mobile
    font-size: 11px; // Adjust font size for mobile
    left: 15px;
    bottom: 30px;
  }
}

.toast-container::before {
  content: '';
  position: absolute;
  width: 8px;
  top: 0px;
  left: 0px;
  height: 100%;
}

.success-message {
  border: 1px solid rgba(89, 158, 37, 0.4);

  &::before {
    background: var(--success-toast-color) !important;
  }
}

.error-message {
  &::before {
    background: #b71c27 !important;
  }
}

.warning-message {
  border: 1px solid rgba(164, 115, 0, 0.4);

  &::before {
    background: var(--warning-toast-color) !important;
  }
}

.toast-container > div {
  font-family: 'montserrat-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 400;
  font-size: 13px;
  text-align: start;
  line-height: 120%;
  word-wrap: break-word; /* For long words */
  overflow-wrap: break-word; /* Ensures words break when needed */
  word-break: break-all; /* Forces breaks within long words */
}

.toast-container svg path {
  stroke: none !important;
  height: 31px !important;
  width: 31px !important;
}

.Toastify__toast-body {
  display: flex;
  // align-items: flex-start !important;
  // justify-content: center !important;
}

.Toastify__toast-icon {
  margin-inline-start: 15px;
  .format-icon {
    path {
      fill: none !important;
      stroke: var(--left-side-bar-menu-icons-color) !important;
    }
  }
}

.Toastify__toast-container--bottom-right {
  bottom: -4em !important;
  right: 1em;
}
