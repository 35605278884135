.verify-title-owner {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #074EDC;
  font-weight: 500;
  padding-top: 65px;
}
.verification-text-owner {
  // display: flex;
  // justify-content: center;
  // text-align: center;
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
  // margin-bottom: 70px;
  padding-top: 10px;
}
.like-ownership {
  display: flex;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
  // margin-bottom: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.terms {
  .MuiCheckbox-root {
    margin-top: -35px !important;
  }
}
.owner-button {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding-top:90px;
}
.no-button {
  width: 133px;
  height: 33px;
  border-radius: 18px;
  border: none;
  background-color: #d8d8d8;
  color: #707070;
  cursor: pointer;
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.yes-button {
  width: 133px;
  height: 33px;
  border-radius: 18px;
  border: none;
  background-color: #165ad3;
  color: white;
  cursor: pointer;
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.button-disabled {
  transition: all 0.2s ease;
  opacity: 0.8 !important;
  pointer-events: none !important;
  cursor: no-drop;
}
.loader-container {
  display: flex;
  // margin-right: 20px;
  svg {
    color: white;
    circle {
      stroke: white !important;
    }
  }
}
.login-text {
  font-size: 17px;
  color: var(--text-white) !important;
}

