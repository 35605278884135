.signup-layout-container {
  position: relative;
  @media (max-width: 600px) {
    overflow: hidden !important;
  }
}

.signup-screen-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 600px) {
    display: none;
  }
}

.background-blur-layer {
  @media (max-width: 600px) {
    display: none;
  }
}

.services-element-container {
  opacity: 0;
  transform: translateX(-80%);
  animation: slide-right-in 0.2s ease 0.2s forwards;
}

.form-container {
  opacity: 0;
  transform: translateX(-50px);
  animation: slide-in 0.4s ease forwards;
  position: relative;
  z-index: 1;
  .water-mark-middle-image {
    @media (max-width: 600px) {
      right: -15px !important;
    }
  }
  @media (max-width: 600px) {
    margin: 0px !important;
    .top-right-element-image {
      right: -7px !important;
      top: -20px !important;
    }
  }
}

@keyframes slide-in {
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slide-right-in {
  to {
    opacity: 1;
    transform: translateX(-100%);
  }
}
// .toast-container-above-form{
//   position: fixed;
//   left: 50%;
//   transform: translateX(-50%);
//   padding: 10px;
//   // color: #fff;
//   // background-color: rgba(0, 0, 0, 0.7);
//   z-index: 9999;
// }