@font-face {
  font-family: Avenir;
  src: url('/public/fonts/AvenirLTProLight.otf');
  font-weight: 300;
}
@font-face {
  font-family: Avenir;
  src: url('/public/fonts/AvenirLTProMedium.otf');
  font-weight: 400;
}
@font-face {
  font-family: Avenir;
  src: url('/public/fonts/AvenirLTProHeavy.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/montserrat-variablefont_wght-webfont.woff2') format('woff2'),
    url('/public/fonts/montserrat-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Poppins', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* colors */

:root {
  --primary: #0729c9;
  --secondary: #f8782b;
}

/* text colors */

.text-primary-main {
  color: var(--primary);
}

.text-secondary-main {
  color: var(--secondary);
}
.Toastify__toast {
  /* width: 450px !important; */
  text-align: center;
  right: 23%;
}
.Toastify__toast--default {
  text-align: center;
  background-color: red;
}
