.background-with-layout-container {
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;

  .background-layer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // backdrop-filter: blur(2px);
    z-index: -1;
    @media (max-width: 600px) {
      display: none;
    }
  }

  .signup-screen-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }

  .background-blur-layer {
    @media (max-width: 600px) {
      display: none;
    }
  }
}
